import useSaveAttachedGuardia from './useSaveAttachedGuardia'
import useSaveAttached, {ISaveAttached} from './useSaveAttached'

export {
	useSaveAttached,
	useSaveAttachedGuardia,
}

export type {
	ISaveAttached
}