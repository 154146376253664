import { appointmentServices } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios, { AxiosError } from 'axios'
import { save_chatAtt_attached, save_online_attached } from '@/config/endpoints'
import { useMutation, UseMutationOptions } from 'react-query'

export interface ISaveAttached {
	/** assignationId */
	id: string,
	service: appointmentServices,
	file: File
}

interface ISaveAttachedResponse {
	fileUrl: string,
	assignationId: string,
}

type CustomAxiosError = {
	exception: {
		response: {
			assignationId: string
		}
	}
}

type Options = Omit<UseMutationOptions<ISaveAttachedResponse, AxiosError<CustomAxiosError>, ISaveAttached>, 'mutationKey' | 'mutationFn'>

function useSaveAttached(uid: string, options?: Options) {
	return useMutation<ISaveAttachedResponse, AxiosError<CustomAxiosError>, ISaveAttached>(
		['Megalith-Query','useSaveAttached', uid], async (data: ISaveAttached) => {
			const token = await getFirebaseIdToken()

			const headers = {
				'Authorization': `Bearer ${token}`,
				'uid': uid,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY
			}
			
			/** Utilizamos formData porque en un body normal no podemos mandar el file */
			const saveAttachedData = new FormData()
			saveAttachedData.append('id', data.id)
			saveAttachedData.append('uid', uid)
			saveAttachedData.append('service', data.service)
			saveAttachedData.append('country', process.env.NEXT_PUBLIC_COUNTRY)
			saveAttachedData.append('file', data.file)
			
			let saveAttachedEndpoint = save_online_attached
			if (data.service === 'chatAtt') {
				saveAttachedEndpoint = save_chatAtt_attached
			}

			const saveAttachedResponse = await axios.post<ISaveAttachedResponse>(saveAttachedEndpoint, saveAttachedData, { headers })
			
			return {
				...saveAttachedResponse?.data,
				assignationId: data.id,
			}
		},
		{
			...options,
		}
	)
}

export default useSaveAttached